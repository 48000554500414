import axios from "axios";
import { AUTH_TOKEN, AUTH_USER } from "../redux/constants/Auth";
axios.interceptors.request.use((config) => {
  const reactJSId = sessionStorage.getItem("reactJSId");
  config.headers = {
    ...config.headers,
    "React-App-Id": reactJSId || "_",
  };
  return config;
});
axios.interceptors.response.use(
  function (response) {
    try {
      if (response.data.slice(0, 15) === "<!doctype html>") {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_USER);
        window.location.reload();
      } else {
        return response;
      }
    } catch (e) {
      return response;
    }
  },
  function (error) {
    try {
      if (error.config.url === "/api/login") {
        return error;
      }
    } catch (e) {
      console.error("Login error", e);
    }
    if (error.response === undefined) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_USER);
      window.location.reload();
    } else {
      return error;
    }
  }
);

const apiService = {};

apiService.login = async function (username, password) {
  return await axios
    .post("/api/login", null, { params: { username, password } })
    .then((req) => {
      try {
        return req.status === 200;
      } catch (e) {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};

apiService.logout = async function () {
  return await axios
    .get("/api/logout")
    .then(() => true)
    .catch(() => false);
};

apiService.getAdminUser = function () {
  return axios.get("/api/users/adminUser");
};

apiService.createDefaultUser = function (email, password) {
  return axios.post("/api/users/createDefaultUser", { email, password });
};

apiService.usersGetAuthUser = async function () {
  return await axios
    .get("/api/users/authUser")
    .then((res) => res.data)
    .catch(() => null);
};

apiService.usersGetAuthUserAsync = async function () {
  return await axios.get("/api/users/authUser");
};

apiService.usersGetAllBalance = function (date) {
  return axios.post("/api/admin/balanceHistory/sumByDate", { date });
};

apiService.usersGetAll = function ({ page, size, sort, search }) {
  return axios.get("/api/users", {
    params: { page: page - 1, size, sort, search },
  });
};

apiService.usersGetSort = function ({ page, size, sort, params }) {
  return axios.post("/api/admin/user/query", {
    page: page - 1,
    size,
    sort,
    params,
  });
};

apiService.userDataGetById = function (id) {
  return axios.get("/api/admin/user/byId", {
    params: { id },
  });
};

apiService.userBalanseHistiryGetById = function (params) {
  return axios.post("/api/admin/balanceHistory/byUser", params);
};

apiService.userBalanseCorrect = function (params) {
  return axios.post("/api/admin/balance/correct", params);
};

apiService.userSetPassword = function (params) {
  return axios.post("/api/users/setPassword", params);
};

apiService.usersGetAllNoPage = function () {
  return axios.get("/api/users/no-page");
};

apiService.userProfile = function () {
  return axios.get(`/api/users/profile`);
};

apiService.usersGetUser = function (id) {
  return axios.get("/api/users/entity", { params: { id } });
};

apiService.usersSetNewPassword = function (newPassword) {
  return axios.post("/api/users/password", newPassword);
};

apiService.usersCreate = function (user) {
  return axios.post("/api/users/createUser", {
    name: user.name,
    email: user.email,
    password: user.password,
    roles: [],
  });
};

apiService.usersDelete = function (id) {
  return axios.delete("/api/users", { params: { id } });
};

apiService.usersSaveProfile = function (userProfile) {
  return axios.post("/api/users/profile", userProfile);
};

apiService.translationGetAll = function () {
  return axios.get("/api/translation/all");
};

apiService.translationGet = function (id) {
  return axios.get("/api/translation", { params: { id } });
};

apiService.translationCreate = function (
  name,
  languageCode,
  languageNameInternational,
  languageNameOriginal,
  rfc4646
) {
  return axios.put("/api/translation", {
    name,
    languageCode,
    languageNameInternational,
    languageNameOriginal,
    rfc4646,
  });
};

apiService.translationSave = function (
  id,
  name,
  languageCode,
  languageNameInternational,
  languageNameOriginal,
  rfc4646
) {
  return axios.post("/api/translation", {
    id,
    name,
    languageCode,
    languageNameInternational,
    languageNameOriginal,
    rfc4646,
  });
};

apiService.pageGetAll = function () {
  return axios.get("/api/page/all");
};

apiService.pageGet = function (id) {
  return axios.get("/api/page", { params: { id } });
};

apiService.pageCreate = function (name, tag, pathname) {
  return axios.put("/api/page", { name, tag, pathname });
};

apiService.pageSave = function (id, name, tag, pathname) {
  return axios.post("/api/page", { id, name, tag, pathname });
};

apiService.pageContentGet = function (pageId, translationId) {
  return axios.get("/api/page/content", {
    params: { pageId, translationId },
  });
};

apiService.pageFrontContentGet = function (tag, languageCode) {
  return axios.get("/api/page/front/content", {
    params: { tag, languageCode },
  });
};

apiService.pageContentSave = function (
  pageId,
  translationId,
  title,
  description,
  hContent,
  h1,
  h1Text,
  h2,
  h2Text
) {
  return axios.post("/api/page/content", {
    pageId,
    translationId,
    title,
    description,
    hContent,
    h1,
    h1Text,
    h2,
    h2Text,
  });
};

apiService.siteGetAll = function () {
  return axios.get("/api/site/get/all");
};

apiService.siteTopGetAll = function () {
  return axios.get("/api/site/top");
};

apiService.siteTopAdd = function (siteId) {
  return axios.post("/api/site/top/create", { siteId });
};

apiService.siteTopSave = function (siteTop) {
  return axios.post("/api/site/top", { siteTop });
};

apiService.siteTopDelete = function (id) {
  return axios.delete("/api/site/top", { params: { id } });
};

apiService.siteProxyGetAll = function () {
  return axios.get("/api/site/proxy/get/all");
};

apiService.siteDelete = function (id) {
  return axios.delete("/api/site", { params: { id } });
};

apiService.siteProxyDelete = function (id) {
  return axios.delete("/api/site/proxy", { params: { id } });
};

apiService.siteProxyDeleteImage = function (id) {
  return axios.delete("/api/site/proxy/image", { params: { id } });
};

apiService.siteProxySave = function (
  id,
  name,
  email,
  description,
  link,
  freeTest,
  individual,
  replacementPossibility,
  countries,
  ipType,
  purposeUse,
  priceMax,
  minRentPeriod,
  clientNoAuth,
  clientLoginAuth,
  clientIPAuth,
  refunds,
  affiliateProgram,
  userId,
  promoCode
) {
  return axios.post("/api/site/proxy", {
    id,
    name,
    email,
    description,
    link,
    freeTest,
    individual,
    replacementPossibility,
    countries,
    ipType,
    purposeUse,
    priceMax,
    minRentPeriod,
    clientNoAuth,
    clientLoginAuth,
    clientIPAuth,
    refunds,
    affiliateProgram,
    userId,
    promoCode,
  });
};

apiService.siteProxyGet = function (id) {
  return axios.get("/api/site/proxy", { params: { id } });
};

// apiService.goalGetAll = function () {
//     return axios.get('/api/goal/front/all')
// }

apiService.paymentSystemsGetAll = function () {
  return axios.get("/api/admin/paymentSystem/all");
};

apiService.paymentSystemsGet = function (id) {
  return axios.get("/api/admin/paymentSystem", { params: { id } });
};

apiService.paymentSystemsSave = function (params) {
  return axios.post("/api/admin/paymentSystem", params);
};

apiService.paymentSystemsCreate = function (params) {
  return axios.put("/api/admin/paymentSystem", params);
};

apiService.paymentSystemsDelete = function (id) {
  return axios.delete("/api/admin/paymentSystem", { params: { id } });
};

apiService.changePaymentSystemsOrder = function (paymentSystemSortedIds) {
  return axios.post("/api/admin/paymentSystem/changeIndices", {
    paymentSystemSortedIds,
  });
};

apiService.createMinimalPriceForPaymentSystem = function (
  paymentSystemId,
  currencyId,
  minPrice
) {
  return axios.put("/api/admin/minimalPriceForPaymentSystem", {
    paymentSystemId,
    currencyId,
    minPrice,
  });
};

apiService.saveMinimalPriceForPaymentSystem = function (
  id,
  paymentSystemId,
  currencyId,
  minPrice
) {
  return axios.post("/api/admin/minimalPriceForPaymentSystem", {
    id,
    paymentSystemId,
    currencyId,
    minPrice,
  });
};

apiService.deleteMinimalPriceForPaymentSystem = function (id) {
  return axios.delete("/api/admin/minimalPriceForPaymentSystem", {
    params: { id },
  });
};

apiService.goalGetAll = function () {
  return axios.get("/api/admin/project/all");
};

apiService.goalGet = function (id) {
  return axios.get("/api/admin/project", { params: { id } });
};

apiService.goalDelete = function (id) {
  return axios.delete("/api/admin/project", { params: { id } });
};

apiService.goalSave = function (id, name, code) {
  return axios.post("/api/admin/project", {
    id,
    name,
    code,
  });
};

apiService.goalCreate = function (name, code) {
  return axios.put("/api/admin/project", { name, code });
};

apiService.goalGroupGetAll = function () {
  return axios.get("/api/admin/project/group/all");
};

apiService.goalGroupGet = function (id) {
  return axios.get("/api/admin/project/group", { params: { id } });
};

apiService.goalGroupDelete = function (id) {
  return axios.delete("/api/admin/project/group", { params: { id } });
};

apiService.goalGroupSave = function (id, name, code, localeCode, showOnFront) {
  return axios.post("/api/admin/project/group", {
    id,
    name,
    code,
    localeCode,
    showOnFront,
  });
};

apiService.goalGroupCreate = function (name, code, localeCode) {
  return axios.put("/api/admin/project/group", { name, code, localeCode });
};

apiService.countriesGetAll = function () {
  return axios.get("/api/admin/country/all");
};

apiService.countriesGet = function (id) {
  return axios.get("/api/admin/country", { params: { id } });
};

apiService.countriesDelete = function (id) {
  return axios.delete("/api/admin/country", { params: { id } });
};

apiService.countriesSave = function (params) {
  return axios.post("/api/admin/country", params);
};

apiService.countriesCreate = function (params) {
  return axios.put("/api/admin/country", params);
};

apiService.changeCountryOrder = function (countriesSortedIds) {
  return axios.post("/api/admin/country/changeIndices", {
    countriesSortedIds,
  });
};
apiService.getAllReviews = function () {
  return axios.get("/api/admin/siteReview/all");
};

apiService.getAllReviewsByLocale = function () {
  return axios.get("/api/admin/siteReview/all/groupBy/locale");
};

apiService.getReviewById = function (id) {
  return axios.get("/api/admin/siteReview", { params: { id } });
};

apiService.createReview = function (
  name,
  avatarUrl,
  siteName,
  siteUrl,
  text,
  locale
) {
  return axios.put("/api/admin/siteReview", {
    name,
    avatarUrl,
    siteName,
    siteUrl,
    text,
    locale,
  });
};

apiService.saveReview = function (
  id,
  name,
  avatarUrl,
  siteName,
  siteUrl,
  text,
  locale
) {
  return axios.post("/api/admin/siteReview", {
    id,
    name,
    avatarUrl,
    siteName,
    siteUrl,
    text,
    locale,
  });
};

apiService.deleteReview = function (id) {
  return axios.delete("/api/admin/siteReview", { params: { id } });
};

apiService.getCurrencies = function () {
  return axios.get("/api/admin/currency/all");
};

apiService.getCurrencyById = function (id) {
  return axios.get("/api/admin/currency", { params: { id } });
};

apiService.createCurrency = function (
  code,
  isCalculate,
  isMain,
  divideOnExchange,
  showOnFront,
  localeCodes
) {
  return axios.put("/api/admin/currency", {
    code,
    isCalculate,
    isMain,
    divideOnExchange,
    showOnFront,
    localeCodes,
  });
};

apiService.saveCurrency = function (
  id,
  code,
  isCalculate,
  isMain,
  divideOnExchange,
  showOnFront,
  localeCodes
) {
  return axios.post("/api/admin/currency", {
    id,
    code,
    isCalculate,
    isMain,
    divideOnExchange,
    showOnFront,
    localeCodes,
  });
};

apiService.deleteCurrency = function (id) {
  return axios.delete("/api/admin/currency", { params: { id } });
};

apiService.getPrices = function () {
  return axios.get("/api/admin/price/all");
};

apiService.getPricesIPv4 = function () {
  return axios.get("/api/admin/price/all/groupByCurrency");
};

apiService.getPricesIPv6 = function () {
  return axios.get("/api/admin/priceIPv6/all");
};

apiService.getPricesISP = function () {
  return axios.get("/api/admin/priceISP/all");
};

apiService.savePriceISP = function (params) {
  return axios.post("/api/admin/priceISP", params);
};

apiService.getPricesMobileAll = function () {
  return axios.get("/api/admin/priceMobile/all");
};

apiService.settingsASES = function () {
  return axios.get(`/api/admin/settings/email/ases`);
};

apiService.settingsASESSave = function (params) {
  return axios.post(`/api/admin/settings/email/ases`, params);
};

apiService.settingsFullInit = function () {
  return axios.post(`/api/settings/full-init`);
};

apiService.settingsClearCache = function () {
  return axios.get(`/api/front/clear/cache`);
};

apiService.getUserBalanceSetting = function () {
  return axios.get("/api/admin/settings/balance");
};

apiService.setUserBalanceSetting = function (params) {
  return axios.post("/api/admin/settings/balance", params);
};

apiService.getTelegramSetting = function () {
  return axios.get("/api/admin/settings/telegram");
};

apiService.setTelegramSetting = function (params) {
  return axios.post("/api/admin/settings/telegram", params);
};

apiService.getWhatsAppSetting = function () {
  return axios.get("/api/admin/settings/whatsApp");
};

apiService.setWhatsAppSetting = function (params) {
  return axios.post("/api/admin/settings/whatsApp", params);
};

apiService.getPaddleSubscriptionConfig = function () {
  return axios.get("/api/admin/settings/paddle/subscription");
};

apiService.setPaddleSubscriptionConfig = function (params) {
  return axios.post("/api/admin/settings/paddle/subscription", params);
};

apiService.getPipeDriveSetting = function () {
  return axios.get("/api/admin/settings/pipedrive");
};

apiService.setPipeDriveSetting = function (params) {
  return axios.post("/api/admin/settings/pipedrive", params);
};

apiService.getValidateEmailSetting = function () {
  return axios.get("/api/admin/settings/email/checker");
};

apiService.setValidateEmailSetting = function (params) {
  return axios.post("/api/admin/settings/email/checker", params);
};

apiService.getTypeProxiesSettings = function () {
  return axios.get(`/api/admin/settings/proxyTypes`);
};

apiService.setTypeProxiesSettings = function (params) {
  return axios.post(`/api/admin/settings/proxyTypes`, params);
};

apiService.getGoalsSettings = function () {
  return axios.get(`/api/admin/settings/project`);
};

apiService.setGoalsSettings = function (params) {
  return axios.post(`/api/admin/settings/project`, params);
};

apiService.getCountDiscount = function () {
  return axios.get("/api/admin/discountForNumberOfProxies/all");
};
apiService.getCountDiscountIPv6 = function () {
  return axios.get("/api/admin/discountForNumberOfProxiesIPv6/all");
};
apiService.getCountDiscountMobile = function () {
  return axios.get("/api/admin/discountForNumberOfProxiesMobile/all");
};

apiService.getCountDiscountISP = function () {
  return axios.get("/api/admin/discountForNumberOfProxiesISP/all");
};

apiService.getCountDiscountById = function (id, typeProxy) {
  return axios.get(`/api/admin/discountForNumberOfProxies${typeProxy}`, {
    params: { id },
  });
};

apiService.createCountDiscount = function (count, discount, typeProxy) {
  return axios.put(`/api/admin/discountForNumberOfProxies${typeProxy}`, {
    count,
    discount,
  });
};

apiService.saveCountDiscount = function (id, count, discount, typeProxy) {
  return axios.post(`/api/admin/discountForNumberOfProxies${typeProxy}`, {
    id,
    count,
    discount,
  });
};

apiService.deleteCountDiscount = function (id, typeProxy) {
  return axios.delete(`/api/admin/discountForNumberOfProxies${typeProxy}`, {
    params: { id },
  });
};

apiService.getDaysDiscount = function () {
  return axios.get("/api/admin/discountForNumberOfDays/all");
};

apiService.getDaysDiscountIPv6 = function () {
  return axios.get("/api/admin/discountForNumberOfDaysIPv6/all");
};

apiService.getDaysDiscountISP = function () {
  return axios.get("/api/admin/discountForNumberOfDaysISP/all");
};

apiService.getDaysDiscountMobile = function () {
  return axios.get("/api/admin/discountForNumberOfDaysMobile/all");
};

apiService.getDaysDiscountById = function (id, typeProxy) {
  return axios.get(`/api/admin/discountForNumberOfDays${typeProxy}`, {
    params: { id },
  });
};

apiService.createDaysDiscount = function (days, discount, typeProxy) {
  return axios.put(`/api/admin/discountForNumberOfDays${typeProxy}`, {
    days,
    discount,
  });
};

apiService.saveDaysDiscount = function (id, days, discount, typeProxy) {
  return axios.post(`/api/admin/discountForNumberOfDays${typeProxy}`, {
    id,
    days,
    discount,
  });
};

apiService.deleteDaysDiscount = function (id, typeProxy) {
  return axios.delete(`/api/admin/discountForNumberOfDays${typeProxy}`, {
    params: { id },
  });
};

apiService.getRentPeriod = function () {
  return axios.get("/api/admin/rentPeriod/all");
};

apiService.getRentPeriodById = function (id) {
  return axios.get("/api/admin/rentPeriod", { params: { id } });
};

apiService.getRentPeriodsByProxyType = function (proxyType) {
  return axios.get("/api/front/rentPeriod/byType", { params: { proxyType } });
};

apiService.getTagsMobileOperatorsFromCRM = function () {
  return axios.get("/api/admin/mobileTariff/tagsFromCRM");
};

apiService.createRentPeriod = function (params) {
  return axios.put("/api/admin/rentPeriod", params);
};

apiService.changePriceForProject = function (
  id,
  projectId,
  currencyId,
  countryId,
  amount
) {
  return axios.post("/api/admin/priceForProjectService", {
    id,
    projectId,
    currencyId,
    countryId,
    amount,
  });
};

apiService.addPriceForProject = function (
  projectId,
  currencyId,
  countryId,
  amount
) {
  return axios.put("/api/admin/priceForProjectService", {
    projectId,
    currencyId,
    countryId,
    amount,
  });
};

apiService.deletePriceForProject = function (id) {
  return axios.delete("/api/admin/priceForProjectService", {
    params: { id },
  });
};

apiService.getMobileTariffAll = function () {
  return axios.get("/api/admin/mobileTariff/all");
};

apiService.getMobileTariffById = function (id) {
  return axios.get("/api/admin/mobileTariff", { params: { id } });
};

apiService.saveMobileTariff = function (params) {
  return axios.post("/api/admin/mobileTariff", params);
};

apiService.addMobileTariff = function (params) {
  return axios.put("/api/admin/mobileTariff", params);
};

apiService.deleteMobileTariff = function (id) {
  return axios.delete("/api/admin/mobileTariff", { params: { id } });
};

apiService.getSyncronizeMobileOperators = function () {
  return axios.get("/api/admin/mobileTariff/update/crm");
};

apiService.changeIndicesMobileTariff = function (mobileTariffSortedIds) {
  return axios.post("/api/admin/mobileTariff/changeIndices", {
    mobileTariffSortedIds,
  });
};

apiService.saveRentPeriod = function (params) {
  return axios.post("/api/admin/rentPeriod", params);
};

apiService.deleteRentPeriod = function (id) {
  return axios.delete("/api/admin/rentPeriod", { params: { id } });
};

apiService.getMetaDate = function () {
  return axios.get("/api/admin/metaDate/all");
};
apiService.getMetaDateByLocale = function () {
  return axios.get("/api/admin/metaDate/all/groupBy/locale");
};

apiService.getMetaDateById = function (id) {
  return axios.get("/api/admin/metaDate", { params: { id } });
};

apiService.createMetaDate = function (attribute, language, value) {
  return axios.put("/api/admin/metaDate", {
    attribute,
    language,
    value,
  });
};

apiService.saveMetaDate = function (id, attribute, language, value) {
  return axios.post("/api/admin/metaDate", {
    id,
    attribute,
    language,
    value,
  });
};

apiService.deleteMetaDate = function (id) {
  return axios.delete("/api/admin/metaDate", { params: { id } });
};

apiService.getTips = function () {
  return axios.get("/api/admin/tips/all/groupBy/locale");
};

apiService.changeTipsOrder = function (tipsSortedIds) {
  return axios.post("/api/admin/tips/changeIndices", {
    tipsSortedIds,
  });
};

apiService.getTipsById = function (id) {
  return axios.get("/api/admin/tips", { params: { id } });
};

apiService.createTips = function (params) {
  return axios.put("/api/admin/tips", params);
};

apiService.saveTips = function (params) {
  return axios.post("/api/admin/tips", params);
};

apiService.deleteTips = function (id) {
  return axios.delete("/api/admin/tips", { params: { id } });
};

apiService.getAdvantages = function () {
  return axios.get("/api/admin/advantages/all/groupBy/locale");
};

apiService.getAdvantagesById = function (id) {
  return axios.get("/api/admin/advantages", { params: { id } });
};

apiService.saveAdvantages = function (params) {
  return axios.post("/api/admin/advantages", params);
};
apiService.changeAdvantagesOrder = function (advantagesSortedIds) {
  return axios.post("/api/admin/advantages/changeIndices", {
    advantagesSortedIds,
  });
};

apiService.createAdvantages = function (params) {
  return axios.put("/api/admin/advantages", params);
};

apiService.deleteAdvantages = function (id) {
  return axios.delete("/api/admin/advantages", { params: { id } });
};

apiService.getFaq = function () {
  return axios.get("/api/admin/faq/all");
};

apiService.getFaqByLocale = function () {
  return axios.get("/api/admin/faq/all/groupBy/locale");
};

apiService.getFaqById = function (id) {
  return axios.get("/api/admin/faq", { params: { id } });
};

apiService.createFaq = function (showOnFront, locale, question, answer, index) {
  return axios.put("/api/admin/faq", {
    showOnFront,
    locale,
    question,
    answer,
    index,
  });
};

apiService.saveFaq = function (
  id,
  showOnFront,
  locale,
  question,
  answer,
  index
) {
  return axios.post("/api/admin/faq", {
    id,
    showOnFront,
    locale,
    question,
    answer,
    index,
  });
};

apiService.deleteFaq = function (id) {
  return axios.delete("/api/admin/faq", { params: { id } });
};

apiService.changeFaqOrder = function (faqSortedIds) {
  return axios.post("/api/admin/faq/changeIndices", {
    faqSortedIds,
  });
};

// FAQ V2
apiService.getFaqTopicsV2 = function () {
  return axios.get("/api/admin/faqType/v2/all");
};

//FAQ v3
apiService.getFaqTopicsV3 = function () {
  return axios.get("/api/admin/faqType/v3/all");
};

apiService.getFaqTopicByIdV2 = function (id) {
  return axios.get("/api/admin/faqType/v2/byId", { params: { id } });
};

apiService.getFaqTopicByIdV3 = function (id) {
  return axios.get("/api/admin/faqType/v3/byId", { params: { id } });
};

apiService.createFaqTopicV2 = function (params) {
  return axios.put("/api/admin/faqType/v2/create", params);
};

apiService.createFaqTopicV3 = function (params) {
  return axios.put("/api/admin/faqType/v3/create", params);
};

apiService.updateFaqTopicV2 = function (params) {
  return axios.post("/api/admin/faqType/v2/save", params);
};

apiService.updateFaqTopicV3 = function (params) {
  return axios.post("/api/admin/faqType/v3/save", params);
};

apiService.changeOrderFaqTopicsV2 = function (faqSortedIds) {
  return axios.post("/api/admin/faqType/v2/changeIndices", {
    faqSortedIds,
  });
};

apiService.changeOrderFaqTopicsV3 = function (faqSortedIds) {
  return axios.post("/api/admin/faqType/v3/changeIndices", {
    faqSortedIds,
  });
};

apiService.deleteFaqTopicV2 = function (id) {
  return axios.delete("/api/admin/faqType/v2/delete", { params: { id } });
};

apiService.deleteFaqTopicV3 = function (id) {
  return axios.delete("/api/admin/faqType/v3/delete", { params: { id } });
};

// FAQ V2 Items
apiService.getFaqItemsByLocaleAndTopicIdV2 = function (params) {
  return axios.get("/api/admin/faq/v2/by/locale/faqType", { params });
};

apiService.getFaqItemsByLocaleAndTopicIdV3 = function (params) {
  return axios.get("/api/admin/faq/v3/by/locale/faqType", { params });
};

apiService.getFaqItemByIdV2 = function (id) {
  return axios.get("/api/admin/faq/v2/byId", { params: { id } });
};

apiService.getFaqItemByIdV3 = function (id) {
  return axios.get("/api/admin/faq/v3/byId", { params: { id } });
};

apiService.createFaqItemV2 = function (params) {
  return axios.put("/api/admin/faq/v2/create", params);
};

apiService.createFaqItemV3 = function (params) {
  return axios.put("/api/admin/faq/v3/create", params);
};

apiService.updateFaqItemV2 = function (params) {
  return axios.post("/api/admin/faq/v2/save", params);
};

apiService.updateFaqItemV3 = function (params) {
  return axios.post("/api/admin/faq/v3/save", params);
};

apiService.changeOrderFaqItemsV2 = function (faqSortedIds) {
  return axios.post("/api/admin/faq/v2/changeIndices", { faqSortedIds });
};

apiService.changeOrderFaqItemsV3 = function (faqSortedIds) {
  return axios.post("/api/admin/faq/v3/changeIndices", { faqSortedIds });
};


apiService.deleteFaqItemV2 = function (id) {
  return axios.delete("/api/admin/faq/v2/delete", { params: { id } });
};

apiService.deleteFaqItemV3 = function (id) {
  return axios.delete("/api/admin/faq/v3/delete", { params: { id } });
};

// FAQ V2 end

apiService.renewDB = function () {
  return axios.get("/api/test/fixPrices");
};

apiService.getAllLanguages = function () {
  return axios.get("/api/admin/language/all");
};

apiService.getMailsOnProcessInfo = function () {
  return axios.get("/api/email/getInfo");
};

apiService.getEmailTemplatesByLocale = function () {
  return axios.get("/api/admin/emailTemplate/all/groupBy/locale");
};

apiService.getEmailTemplateById = function (id) {
  return axios.get("/api/admin/emailTemplate", {
    params: { id },
  });
};

apiService.saveEmailTemplateById = function (
  id,
  name,
  locale,
  subject,
  text,
  dateFormatTemplateId,
  ipStatus,
  hasIpList
) {
  return axios.post("/api/admin/emailTemplate", {
    id,
    locale,
    name,
    subject,
    text,
    dateFormatTemplateId,
    ipStatus,
    hasIpList,
  });
};

apiService.deleteEmailTemplate = function (id) {
  return axios.delete("/api/admin/emailTemplate", { params: { id } });
};

apiService.getAllDateFormats = function () {
  return axios.get("/api/admin/dateFormatTemplate/all");
};

apiService.getDateFormatById = function (id) {
  return axios.get("/api/admin/dateFormatTemplate", { params: { id } });
};

apiService.saveDateFormatById = function (id, template) {
  return axios.post("/api/admin/dateFormatTemplate", { id, template });
};

apiService.createDateFormat = function (template) {
  return axios.put("/api/admin/dateFormatTemplate", { template });
};

apiService.deleteDateFormat = function (id) {
  return axios.delete("/api/admin/dateFormatTemplate", { params: { id } });
};

apiService.getCaptchaKey = function () {
  return axios.get("/api/captcha/key");
};

apiService.getCaptchaSettings = function () {
  return axios.get("/api/admin/settings/google/captcha");
};

apiService.getCfCaptchaSettings = function () {
  return axios.get("/api/admin/settings/cloud/captcha");
};

apiService.saveCfCaptchaSettings = function (params) {
  return axios.post("/api/admin/settings/cloud/captcha", params);
};

apiService.saveCaptchaSettings = function (params) {
  return axios.post("/api/admin/settings/google/captcha", params);
};

apiService.getGa4analiticsSettings = function () {
  return axios.get("/api/admin/settings/ga4");
};

apiService.saveGa4analiticsSettings = function (params) {
  return axios.post("/api/admin/settings/ga4", params);
};

apiService.getGa4HistoryOrders = function (params) {
  return axios.post("/api/admin/ga4/request/history/query", {
    ...params,
    page: params.page - 1,
  });
};

apiService.getGa4HistoryById = function (id) {
  return axios.get("/api/admin/ga4/request/history/byId", {
    params: { id },
  });
};

apiService.getCRMSettings = function () {
  return axios.get("/api/admin/settings/crm");
};

apiService.saveCRMSettings = function (
  proxy_CRM_host_prod,
  proxy_CRM_host_stage,
  proxy_CRM_proxy_port,
  proxy_CRM_socks_port,
  proxy_CRM_test_mode,
  token_proxy_crm_prod,
  token_proxy_crm_stage,
  proxy_CRM_connect
) {
  return axios.post("/api/admin/settings/crm", {
    proxy_CRM_host_prod,
    proxy_CRM_host_stage,
    proxy_CRM_proxy_port,
    proxy_CRM_socks_port,
    proxy_CRM_test_mode,
    token_proxy_crm_prod,
    token_proxy_crm_stage,
    proxy_CRM_connect,
  });
};

apiService.getHistoryCrm = function (params) {
  return axios.post("/api/admin/crm/request/history/query", {
    ...params,
    page: params.page - 1,
  });
};

apiService.getRequestTypes = function () {
  return axios.get("/api/admin/crm/request/history/types");
};

apiService.getHistoryCrmItemById = function (id) {
  return axios.get("/api/admin/crm/request/history/byId", { params: { id } });
};

apiService.getOrders = function (params) {
  return axios.post("/api/admin/order", { ...params, page: params.page - 1 });
};

apiService.getOrderById = function (id) {
  return axios.get("/api/admin/orderById", { params: { id } });
};

apiService.saveOrder = function (orderId, projectId, payed) {
  return axios.post("/api/admin/changeProject", {
    orderId,
    projectId,
    payed,
  });
};

apiService.confirmOrderPaymentById = function (id) {
  return axios.get("/api/admin/payment/confirmedById", { params: { id } });
};

apiService.getReplenishments = function (params) {
  return axios.post("/api/admin/payment/getReplenishmentBalance", {
    ...params,
    page: params.page - 1,
  });
};

apiService.confirmedReplenishmentsById = function (id) {
  return axios.get("/api/admin/payment/confirmedById", {
    params: { id },
  });
};

apiService.saveToken = function (email, token) {
  return axios.put("/api/save/token", {
    email,
    token,
  });
};

apiService.updateCurrency = function () {
  return axios.get("/api/admin/currency/update");
};

apiService.updatePricesIpv4 = function () {
  return axios.post("/api/price/updateAll");
};

apiService.saveIPv6Prices = function (params) {
  return axios.post("/api/admin/priceIPv6", params);
};

apiService.getViewsByProjectId = function (projectId) {
  return axios.get("/api/admin/projectView/byProjectId", {
    params: { projectId },
  });
};

apiService.getViewById = function (id) {
  return axios.get("/api/admin/projectView", {
    params: { id },
  });
};

apiService.saveView = function (id, projectId, displayName, searchCriteria) {
  return axios.post("/api/admin/projectView", {
    id,
    projectId,
    displayName,
    searchCriteria,
  });
};

apiService.deleteView = function (id) {
  return axios.delete("/api/admin/projectView", { params: { id } });
};

apiService.createView = function (projectId, displayName, searchCriteria) {
  return axios.put("/api/admin/projectView", {
    projectId,
    displayName,
    searchCriteria,
  });
};

apiService.getAllCustomProjects = function () {
  return axios.get("/api/admin/customProject/all");
};

apiService.deleteCustomProjectById = function (id) {
  return axios.delete("/api/admin/customProject", { params: { id } });
};

apiService.getExtendProxies = function (orderId) {
  return axios.get("/api/admin/extendOrder/byOrder", { params: { orderId } });
};

apiService.getExtendProxyById = function (id) {
  return axios.get("/api/admin/extendOrder/byId", { params: { id } });
};

apiService.updateIpIds = function (id) {
  return axios.get(" /api/admin/extendOrder/updateIpIds", { params: { id } });
};

apiService.confirmPaymentExtendProxies = function (id) {
  return axios.post("/api/admin/extendOrder/paymentConfirmed", null, {
    params: { id },
  });
};

apiService.downloadMigration = function () {
  return axios.get("/api/migration/project/download");
};

apiService.uploadMigration = function (body) {
  return axios.post("/api/migration/project/upload", { ...body });
};

apiService.downloadMigration = function () {
  return axios.get("/api/migration/project/download");
};

apiService.getSeoByLocale = function (params) {
  return axios.get("/api/admin/siteSeo/allByProxyType/groupByLocale", {
    params,
  });
};

apiService.getSeoById = function (id) {
  return axios.get("/api/admin/siteSeo/byId", { params: { id } });
};

apiService.saveSeo = function (params) {
  return axios.post("/api/admin/siteSeo", params);
};

apiService.addSeo = function (params) {
  return axios.put("/api/admin/siteSeo", params);
};

apiService.deleteSeo = function (id) {
  return axios.delete("/api/admin/siteSeo", { params: { id } });
};

apiService.getSpamTemplate = function () {
  return axios.get("/api/admin/emailTemplateV2/getSpamTemplate");
};

apiService.saveSpamTemplate = function (params) {
  return axios.post("/api/admin/emailTemplateV2/setSpamTemplate", params);
};

apiService.sendSpam = function (params) {
  return axios.post("/api/email/sendSpam", params);
};

apiService.checkSpam = function (params) {
  return axios.post("/api/email/view", params);
};

apiService.getCurentAmountMembers = function (params) {
  return axios.get("/api/email/getInfo/filter", { params });
};

apiService.sendSpamTest = function (params) {
  return axios.post("/api/email/sendTest", params);
};

apiService.getIpsByEmail = function (email, page, size) {
  return axios.post("/api/admin/clientIps/byEmail", { email, page, size });
};

apiService.getIpsById = function (userId, page, size, proxyType) {
  return axios.post("/api/admin/clientIps/byId", {
    userId,
    page,
    size,
    proxyType,
  });
};

apiService.createAccount = function (email, password) {
  return axios.post("/api/users/createAdminUser", { email, password });
};

apiService.getOrdersExtend = function (params) {
  return axios.post("/api/admin/queryOrderExtend", {
    ...params,
    page: params.page - 1,
  });
};

apiService.getCompanyEmailSettings = function () {
  return axios.get("/api/admin/settings/emailCompanySettings");
};
apiService.saveCompanyEmailSettings = function (params) {
  return axios.post("/api/admin/settings/emailCompanySettings", params);
};

apiService.getHeaderTagsSettings = function () {
  return axios.get("/api/admin/settings/headerTagsSettings");
};

apiService.saveHeaderTagsSettings = function (text) {
  return axios.post("/api/admin/settings/headerTagsSettings", {
    text,
  });
};

apiService.getBodyTagsSettings = function () {
  return axios.get("/api/admin/settings/bodyTagsSettings");
};

apiService.saveBodyTagsSettings = function (text) {
  return axios.post("/api/admin/settings/bodyTagsSettings", {
    text,
  });
};

// Block Payment History
apiService.getBlockPaymentHistory = function (params) {
  return axios.post("/api/admin/blockPaymentHistory", {
    ...params,
    page: params.page - 1,
  });
};

apiService.getCountBlockPaymentHistory = function () {
  return axios.get("/api/admin/blockPaymentHistory/count");
};

// NewDesign
apiService.getNewDesignEnabled = function () {
  return axios.post("/api/settings/main/get");
};

apiService.setNewDesignEnabled = function (params) {
  return axios.post("/api/settings/main/set", params);
};

// Debagger
apiService.getDebagger = function () {
  return axios.get("/api/admin/settings/debug");
};

apiService.setDebagger = function (params) {
  return axios.post("/api/admin/settings/debug", params);
};

// v2 autoextend system
apiService.getV2AutoextendSystemSettings = function () {
  return axios.get("/api/admin/settings/autoextendV2");
};

apiService.getHideCountrySettings = function () {
  return axios.get("/api/admin/settings/country")
};

apiService.setV2AutoextendSystemSettings = function (params) {
  return axios.post("/api/admin/settings/autoextendV2", params);
};

apiService.setHideCountrySettings = function (params) {
  return axios.post("/api/admin/settings/country", params);
};


// Web notifications
apiService.getWebNotificationsSettings = function () {
  return axios.get("/api/admin/settings/website/notifications");
};

apiService.setWebNotificationsSettings = function (params) {
  return axios.post("/api/admin/settings/website/notifications", params);
};

// SocketProcess
apiService.getSocketProcess = function () {
  return axios.get("/api/admin/settings/payment/socket/process");
};

apiService.setSocketProcess = function (params) {
  return axios.post("/api/admin/settings/payment/socket/process", params);
};

// Web Notifications
apiService.getWebNotifications = function () {
  return axios.get("/api/admin/webSiteNotificationTemplate/all");
};

apiService.getWebNotificationById = function (id) {
  return axios.get("/api/admin/webSiteNotificationTemplate", {
    params: { id },
  });
};

apiService.saveWebNotification = function (params) {
  return axios.post("/api/admin/webSiteNotificationTemplate", params);
};

apiService.viewWebNotification = function (params) {
  return axios.post("/api/admin/webSiteNotificationTemplate/view", params);
};

// Mail Template V2
apiService.getAllMailTemplatesV2 = function () {
  return axios.get("/api/admin/emailTemplateV2/all");
};

apiService.getMailTemplateByIdV2 = function (id) {
  return axios.get("/api/admin/emailTemplateV2", { params: { id } });
};

apiService.saveMailTemplateV2 = function (params) {
  return axios.post("/api/admin/emailTemplateV2", params);
};

apiService.viewMailTemplateV2 = function (params) {
  return axios.post("/api/admin/emailTemplateV2/view", params);
};

apiService.sendTestEmailV2 = function (params) {
  return axios.post("/api/admin/emailTemplateV2/test", params);
};

/* Files Services */
apiService.file = {};
apiService.file.getById = async function (id) {
  return await axios.get("/api/admin/files/id", { params: { id } });
};

apiService.file.getAll = async function () {
  return await axios.get("/api/admin/files/all");
};

apiService.file.create = async function ({ name, content }) {
  return await axios.post("/api/admin/files/create", { name, content });
};

apiService.file.update = async function ({ name, content }) {
  return await axios.put("/api/admin/files/update", { name, content });
};

apiService.file.deleteById = async function (id) {
  return await axios.delete("/api/admin/files/deleteById", { params: { id } });
};

apiService.getFrontErrorReportPage = function (page, size) {
  return axios.get("/api/admin/front/error/report/list", {
    params: { page, size },
  });
};

apiService.getFrontErrorReportEntity = function (id) {
  return axios.get("/api/admin/front/error/report", { params: { id } });
};

// browser addon
// user-agents controllers
apiService.getUserAgents = function () {
  return axios.get("/api/admin/userAgent/all");
};

apiService.getUserAgentById = function (id) {
  return axios.get("/api/admin/userAgent", { params: { id } });
};

apiService.createUserAgent = function (params) {
  return axios.put("/api/admin/userAgent", params);
};

apiService.updateUserAgent = function (params) {
  return axios.post("/api/admin/userAgent", params);
};

apiService.deleteUserAgent = function (id) {
  return axios.delete("/api/admin/userAgent", { params: { id } });
};

apiService.changeUserAgentsIndices = function (userAgentSortedIds) {
  return axios.post("/api/admin/userAgent/changeIndices", {
    userAgentSortedIds,
  });
};

// user-devices controllers
apiService.getUserDevicesByEmail = function (email) {
  return axios.get("/api/admin/browserUserToken/email", { params: { email } });
};

apiService.getUserDeviceByID = function (token) {
  return axios.get("/api/admin/browserUserToken/token", { params: { token } });
};

// proxy-packeges controllers
apiService.getProxyPackages = function () {
  return axios.get("/api/admin/proxy/package/all");
};

apiService.getProxyPackageById = function (id) {
  return axios.get("/api/admin/proxy/package", { params: { id } });
};

apiService.getProxyPackageByType = function (params) {
  return axios.get("/api/admin/proxy/package/proxyType", { params });
};

apiService.updateProxyPackage = function (params) {
  return axios.post("/api/admin/proxy/package/update", params);
};

export default apiService;
