import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
    AUTH_TOKEN,
    AUTH_USER,
    SIGNIN,
    SIGNOUT,
    SIGNUP,
    SIGNIN_WITH_GOOGLE,
    SIGNIN_WITH_FACEBOOK,
} from "../constants/Auth";
import {
    showAuthMessage,
    authenticated,
    signOutSuccess,
    signUpSuccess,
    signInWithGoogleAuthenticated,
    signInWithFacebookAuthenticated,
} from "../actions/Auth";

import FirebaseService from "services/FirebaseService";
import apiService from "../../services/ApiService";

// export function* signInWithFBEmail() {
//   yield takeEvery(SIGNIN, function* ({payload}) {
// 		const {email, password} = payload;
// 		try {
// 			const user = yield call(FirebaseService.signInEmailRequest, email, password);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(authenticated(user.user.uid));
// 			}
// 		} catch (err) {
// 			yield put(showAuthMessage(err));
// 		}
// 	});
// }

export function* signInWithFBEmail() {
    yield takeEvery(SIGNIN, function* ({ payload }) {
        try {
            // const { email, password, token } = payload;
            const { email, password } = payload;

            // const responseFirst = yield call(apiService.saveToken, email, token);

            // if (responseFirst) {
            const response = yield call(apiService.login, email, password);
            if (response) {
                const auth_token = new Date().getTime();
                localStorage.setItem(AUTH_TOKEN, auth_token);
                const responseUserProfile = yield call(
                    apiService.usersGetAuthUser
                );
                if (responseUserProfile) {
                    if (
                        responseUserProfile.roles.includes("SUPERUSER") ||
                        responseUserProfile.roles.includes("ADMIN")
                    ) {
                        localStorage.setItem(
                            AUTH_USER,
                            JSON.stringify(responseUserProfile)
                        );
                        yield put(authenticated(auth_token));
                    } else {
                        apiService.logout().then(() => {});
                        localStorage.removeItem(AUTH_TOKEN);
                        yield put(showAuthMessage("У вас нет доступа!"));
                    }
                } else {
                    apiService.logout().then(() => {});
                    localStorage.removeItem(AUTH_TOKEN);
                    yield put(
                        showAuthMessage(
                            "Не удалось загрузить данные пользователя!"
                        )
                    );
                }
            } else {
                yield put(showAuthMessage("Не верный логин или пароль"));
            }
            // } else {
            //     yield put(showAuthMessage("Не верный логин или пароль"));
            // }
        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        try {
            const signOutUser = yield call(FirebaseService.signOutRequest);
            if (signOutUser === undefined) {
                apiService.logout().then(() => {});
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(AUTH_USER);
                yield put(signOutSuccess(signOutUser));
            } else {
                yield put(showAuthMessage(signOutUser.message));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export function* signUpWithFBEmail() {
    yield takeEvery(SIGNUP, function* ({ payload }) {
        const { email, password } = payload;
        try {
            const user = yield call(
                FirebaseService.signUpEmailRequest,
                email,
                password
            );
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.user.uid);
                yield put(signUpSuccess(user.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    });
}

export function* signInWithFBGoogle() {
    yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
        try {
            const user = yield call(FirebaseService.signInGoogleRequest);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.user.uid);
                yield put(signInWithGoogleAuthenticated(user.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    });
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
        try {
            const user = yield call(FirebaseService.signInFacebookRequest);
            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.user.uid);
                yield put(signInWithFacebookAuthenticated(user.user.uid));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(signInWithFBEmail),
        fork(signOut),
        fork(signUpWithFBEmail),
        fork(signInWithFBGoogle),
        fork(signInWithFacebook),
    ]);
}
