// import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { genString } from 'utils/genString.helper';
import { useEffect } from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import apiService from 'services/ApiService';
// import { message } from "antd";


const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
    // const [isKeyLoaded, setIsKeyLoaded] = useState(false);
    // const [captchaKey, setCaptchaKey] = useState("");


    // const getKey = () => {
    //     apiService.getCaptchaKey().then((req) => {
    //         if (req && req?.data) {
    //             setCaptchaKey(req.data)
    //         } else {
    //             console.log("Ключ каптчи не был получен");
    //             message.error("Ключ каптчи не был получен");
    //             setCaptchaKey(null);
    //         }
    //         setIsKeyLoaded(true);
    //     });
    // };


    useEffect(() => {
        if (!sessionStorage.reactJSId) {
            sessionStorage.reactJSId = `a${genString(
              15
            )}${new Date().getTime()}${genString(2)}`;
          }
    }, []);



    return (
        <div className="App">
            {/* {isKeyLoaded && (<GoogleReCaptchaProvider reCaptchaKey={captchaKey}> */}
                <Provider store={store}>
                    <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                        <Router>
                            <Switch>
                                <Route path="/" component={Views} />
                            </Switch>
                        </Router>
                    </ThemeSwitcherProvider>
                </Provider>
            {/* </GoogleReCaptchaProvider>)} */}
        </div>
    );
}

export default App;
